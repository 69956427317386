/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:6009b815-f5a5-4d23-93da-9c5072dabd03",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_EGoblayO8",
    "aws_user_pools_web_client_id": "1u3bokh17o3seffhb4q35disr5",
    "oauth": {
        "domain": "ablackcloudapp4b74753a-4b74753a-dev.auth.us-east-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://ablackcloudapp.com/",
        "redirectSignOut": "https://ablackcloudap.com/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_content_delivery_bucket": "ablackcloudapp.com-dev",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "http://ablackcloudapp.com-dev.s3-website-us-east-1.amazonaws.com",
    "aws_appsync_graphqlEndpoint": "https://b3rkqsa65new5lppc7mavfibfm.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS"
};


export default awsmobile;
