import ButtonBase from '@material-ui/core/ButtonBase';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';

export default function AppRender(props){
    const useStyles = makeStyles((theme) => ({
        root: {
          flexGrow: 1,
          backgroundColor: 'transparent !important'
        },
        menuButton: {
          marginRight: theme.spacing(2),
        },
        userNameStyle: {
          position: 'absolute',
          right: '100px', 
        },
        image: {
          position: 'relative',
          height: 200,
          [theme.breakpoints.down('xs')]: {
            width: '100% !important', // Overrides inline-style
            height: 100,
          },
          '&:hover, &$focusVisible': {
            zIndex: 1,
            '& $imageBackdrop': {
              opacity: 0.15,
            },
            '& $imageMarked': {
              opacity: 0,
            },
            '& $imageTitle': {
              border: '4px solid currentColor',
            },
          },
        },
        focusVisible: {},
        imageButton: {
          position: 'absolute',
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          color: theme.palette.common.white,
        },
        imageSrc: {
          position: 'absolute',
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
          backgroundSize: 'cover',
          backgroundPosition: 'center 40%',
        },
        imageBackdrop: {
          position: 'absolute',
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
          backgroundColor: theme.palette.common.black,
          opacity: 0.4,
          transition: theme.transitions.create('opacity'),
        },
        imageTitle: {
          position: 'relative',
          padding: `${theme.spacing(2)}px ${theme.spacing(4)}px ${theme.spacing(1) + 6}px`,
        },
        imageMarked: {
          height: 3,
          width: 18,
          backgroundColor: theme.palette.common.white,
          position: 'absolute',
          bottom: -2,
          left: 'calc(50% - 9px)',
          transition: theme.transitions.create('opacity'),
        },
        appInfo: {
            fontSize: 15,
        }
      }));
      const classes = useStyles();
      return(
          <div>
                <div>
                    <ButtonBase
                      focusRipple
                      key={props.image.title}
                      className={classes.image}
                      focusVisibleClassName={classes.focusVisible}
                      style={{
                        width: props.image.width,
                      }}
                    >
                  <span
                    className={classes.imageSrc}
                    style={{
                      height: 200,
                      backgroundImage: `url(${props.image.url})`,
                    }}
                  />
                  <span 
                    className={classes.imageBackdrop} 
                    style={{
                      height: 200,
                    }}
                  />
                  <Link to="/taskmanagerapp" className="ui button primary">
                    <span className={classes.imageButton}>
                      <Typography
                        component="span"
                        variant="subtitle1"
                        color="inherit"
                        className={classes.imageTitle}
                      >
                        {props.image.title}
                        <span className={classes.imageMarked} />
                      </Typography>
                    </span>
                  </Link>
                  </ButtonBase>
                  </div>            
          </div>
      )
}